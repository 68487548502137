.fh-breadcrumb {
    height: 100vh;
}
.dashboard-col {
    padding: 0px;
    height: calc(100vh - 39px);
}
.dashboard-col .elements-list {
  z-index: 99;
}
.dashboard-col .elements-list .list-group-item:nth-child(2) {
  border-top: 0px;
}
.dashboard-search {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    margin: 0 10px 10px;
}
.dashboard-search:focus {
    border-bottom: 1px solid #ddd;
}
.dashboard-tabs li a {
  border-top: 0px !important;
  border-radius: 0px !important;
  margin: 0px;
  padding: 8px 10px;
}
.dashboard-tabs li:first-child a {
    border-left: 0px !important;
}
.dashboard-tabs li:last-child a {
    border-right: 0px !important;
}
.dashboard-order-detail {
  position: absolute;
  right: 0px;
  top: 0;
  z-index: 200;
  background-color: white;
  border-left: 1px solid #ddd;
  border-right: solid #ddd;
  width: 100%;
  height: calc(100vh - 39px);
  overflow: scroll;
}
.full-height-wrapper {
  padding: 0;
  margin: 0;
}

.full-height-wrapper .ibox-title {
  border-top: 0;
}

.full-height-wrapper .col-lg-12 {
  padding: 0;
}
.multiselect-native-select .dropdown-menu > .active > a {
  background-color: white;
  color: inherit;
}
.multiselect-native-select .btn-group, .multiselect-native-select button.multiselect {
  width: 100%;
  /* max-width: 300px; */
}
.float-e-margins .table-action a, .float-e-margins .table-action button, .table .btn {
  margin-bottom: 0;
}
.react-datepicker__input-container {
  display: inline !important;

}

.react-datepicker__input-container input {
  text-align: center;
}

html {font-size: 16px;}

th.sorting:hover {
  cursor: pointer;
}
.inline-action {
  padding: 20px;
  border-radius: 0px;
  margin: 0;
  background-color: #f6f6f6;
  border-top: 1px solid #e7eaec;
}
.bootstrap-typeahead .close {
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 3;
}

.datatable-nested>td {
  background-color: white;
  padding: 0 !important;
}
.element-detail-box {
  background-color: white;
}
/* datepicker */
.react-datepicker__close-icon {
  position: absolute;
  z-index: 9;
  top: 17px;
}

.form-content-detail {
  padding-bottom: 45px;
}

.table-clients-center {
  text-align: center;
  display: flex;
}

.table-clients-center a {
  margin-right: 4px;
}

.table-clients-center button {
  margin-left: 4px;
}

.table-clients-center i {
  margin: 0 4px;
}

.form-disabled[disabled] {
  background-color: #fff;
}
.u-ellipsis{
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
}
.full-height-scroll{
  overflow-y: hidden;
  height: 100%;
  position: relative;
  backface-visibility: hidden;
}
.u-hidden{
  overflow: hidden;
}
.filter-groups-grid{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
}
.filter-groups-flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
}

.u-flex-center{
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.svg-container {
  width: 200px;
  margin: 2em auto;
}


.u-align-flex{
  display: flex;
  justify-content: space-evenly;
}
.u-wrap-flex{
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.feed-element{
   cursor: pointer;
}
.feed-element:hover, .feed-element:active{
  background: #f4f4f4;
}
.u-flow-flex{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  text-align: center;
}
.u-margin-lr{
  margin: 0 10px;
}
.u-align-right{
  text-align: right;
}
.u-capitalize{
  text-transform: capitalize;
}
.u-flex-basis-label{
  flex-basis: 100px;
}
.e-btn-map{
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 10px;
  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 4px;
}
.e-btn-map a {
  margin: 0 !important;
}
.u-flex-expand{
  flex-grow:1;
}
.u-show{
  display: block;
}
.u-hide{
  display: none;
}
.has-error{
  color:#ed5565 !important;
}
.has-success{
  color: #1bb394;
}
.u-flow-column{
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-grow: 1;
  text-align: center;
}

.u-center-filters{
    display: block;
    align-items: center;
    margin-bottom: 20px;
}
.u-margin-r-5{
  margin-right: 5px;
}
.u-margin-r-10{
  margin-right: 10px;
}
.u-label-white{
  background: white;
  border: 1px solid #dcdbdb;
}
.u-inline{
  display: inline
}
.u-margin-b-20{
  margin-bottom: 20px;
}
.u-margin-l-20{
  margin-left: 20px;
}
.c-price-config-body{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-map{
  position: absolute;
  right: 15px;
  text-align: right;
}

.js-filter-child{
  max-width: 300px;
  margin-left: 1rem;
  margin-right: 1rem;
  flex-basis: 100%;
}

.checkbox-filter-list {
  display: inline-flex;
  justify-self: flex-start;
  grid-row-start: -1;
  grid-row-end: -2;
  grid-column-end: -1;
}

.u-font-normal{
  font-weight: normal;
}
.u-margin-all-20{
  margin: 5px 20px
}
.u-text-upper{
  text-transform: uppercase;
}

.clock-svg{
  width: 10px;
  position: relative;
  top: 1px;
  left: -2px;
}
.progress-in-top{
  display: flex;
  width: 110px;
  justify-content: space-between;
  align-items: center;
}
.progress-bar-width{
  width:50px;
  height:15px;
  margin: 0;
}
.u-bold{
  font-weight: bold;
}
.c-destination-group{
  display: flex;
  margin: 5px 0 5px 0;
  font-size: 10px;
  align-items: center;
  flex-basis: 100%;
  width: 100%;
  justify-content: space-between;
}
.e-destination{
  display: flex;
  flex-basis: 50%;
  align-items: center;
  position:relative;
}

.client-detail-container{
  max-width: 50%;
  display: flex;
  font-weight: normal;
  flex-flow: column;
}
.client-detail-header{
  margin: 5px 0;
  font-size: 14px;
  font-weight: bold;
}
.client-detail-element{
  margin: 2px 0;
  font-size: 12px;
}
.list-group-item{
  height: 150px;
  padding: 10px !important;
}
.container-card-order{
  display: flex;
  flex-flow: column;
  height: 100%;
}
.tags-container {
  display: flex;
  justify-content: flex-end;
}
.type-tag {
  border: 1px solid #19aa8d;
  color: #19aa8d;
  border-radius: 5px;
  padding: 0 2px;
  margin: 1px;
}
.vehicle-type-image {
  width: 36px;
}
.ellipsis-order{
  left: 12px;
  width:90%;
}
.nav-tabs .active {
  pointer-events: none;
}
.client-table-group{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  max-width: 200px;
}
.react-datepicker__time-list{
  padding:0 !important;
}

.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25.5ch;
}

.react-datepicker-wrapper{
  width: 100%;
}

.a-statusList{
  margin:5px; 
  padding:5px;
  display: inline-block;
}

.container-filter-courier{
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  color: #858585;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color .1s ease-out;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg {
  fill: currentColor;
  max-height: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform;
  /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: .2em .6em;
  border-radius: 25px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, .95);
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: .2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname+.emoji-mart-preview-shortname,
.emoji-mart-preview-shortname+.emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon+.emoji-mart-preview-emoticon {
  margin-left: .5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches-opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches-opened .emoji-mart-skin-swatch-selected:after {
  opacity: .75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: .125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: .03s
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: .06s
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: .09s
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: .12s
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: .15s
}

.emoji-mart-skin-swatch-selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch-selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity .2s ease-out;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d
}

.emoji-mart-skin-tone-6 {
  background-color: #594539
}

.form-control{
  border-radius: initial;
  box-shadow: none;
}

.u-align-center{
  width:100%
}

.u-align-center-force{
  float: none !important;
  width: 100%;
  justify-content: center;
}

.e-close-card-infinitive{
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 1em;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.bell{
  display:block;
  margin:0 .5em;
  font-size: 2em;
}
.bell.ring {
  animation: ring 5s 1s ease-in-out infinite;
  transform-origin: 50% 4px;
}

.criteria-error-message {
  display:none;
  font-size:12px;
  color:#f00;
  float:left;
  opacity: 0; 
  transition: opacity 500ms ease-out;
}

.fade-in {
  opacity: 1;
}

.filter-label-element {
    display: block;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
}

.filter-dashboard-label {
  display: block;
  margin: 0.7rem 1rem 0
}

.filter-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 0 0 1rem;
  display: flex;
  flex-direction: column;
}

.tab-bar {
  margin: 15px auto 5px;
  display: flex;
  list-style-type: none;
  padding: 0;
}

.tab {
  width: 135px;
  padding: 0;
  background: white;
  overflow: hidden;
  text-align: center;
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  
  user-select: none;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  border-bottom: 1px solid #f3f3f4;
}

.cercle {
  width: 0px;
  height: 0px;
  position: absolute;
}

.light .cercle {background: white}
.dark .cercle {background: black}

.anim {
  opacity: 0.2;
  animation: touch 1.2s ease-out;
}

.tableRowChild {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px 0 0;
}

@-webkit-keyframes touch {
  100% { 
    width: 600px;
    height: 600px;
    border-radius: 600px;
    opacity: 0;
    margin: -300px;
  }
}

@keyframes touch {
  100% { 
    width: 600px;
    height: 600px;
    border-radius: 600px;
    opacity: 0;
    margin: -300px;
  }
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}